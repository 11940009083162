<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Key.Delete.Title") }}
        </h1>
        <div class="box">
            <h5 class="subtitle is-5">{{ $t("Interface.Key.Delete.Text") }}</h5>
            <article class="message is-danger">
                <div class="message-body">
                    <h6 class="title is-6">{{ $t("Interface.Key.Description") }}</h6>
                    <p>{{ Form.Description }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Key.Expiration") }}</h6>
                    <p>{{ filterDateTime(Form.Expiration) }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Key.AvailableAmount") }}</h6>
                    <p>{{ Form.AvailableAmount }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Key.Price") }}</h6>
                    <p>{{ Form.Price + "&nbsp;" + $t("Interface.Currency."+Form.Currency) }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Key.MainDoor") }}</h6>
                    <p>{{ Form.MainDoor }}</p>
                </div>
            </article>
            <b-button
                type="submit"
                class="button is-theme is-danger"
                :loading="IsLoading"
                @click.prevent="submit"
            >
                {{ $t("Interface.Button.Delete") }}
            </b-button>
        </div>
    </div>
</template>

<script>
import Helpers from "@/utils/Helpers"
import LoadingState from "@/mixins/LoadingState"
import {
    KEY_LIST_REQUEST,
    KEY_DELETE_REQUEST
} from "@/store/actions/key"

export default {
    name: "KeyDelete",
    mixins: [LoadingState],
    data() {
        return {
            Form: {
                Description: null,
                Expiration: null,
                AvailableAmount: null,
                Currency: null,
                Price: null,
                MainDoor: null
            }
        }
    },
    computed: {
        Key() {
            return this.$store.getters.GET_KEY_BY_ID(parseInt(this.$route.params.id, 10))
        }
    },
    mounted() {
        if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
            if (!this.$store.getters.IS_KEYS_LOADED) {
                this.switchLoading()
                this.$store.dispatch(KEY_LIST_REQUEST)
                    .then(() => this.getData())
                    .finally(() => this.switchLoading())
            }
            else this.getData()
        }
        else this.$router.push({ name: "Key-List" })
    },
    methods: {
        getData() {
            this.Form.Description = this.Key.description
            this.Form.Expiration = this.Key.expiration
            this.Form.AvailableAmount = this.Key.availableAmount
            this.Form.Currency = this.Key.currency
            this.Form.Price = this.Key.price
            this.Form.MainDoor = this.Key.doors.find((x) => x.main === true).name
        },
        submit() {
            this.switchLoading()
            this.$store.dispatch(KEY_DELETE_REQUEST, this.$route.params.id)
                .then(() => {
                    this.$store.dispatch(KEY_LIST_REQUEST)
                        .then(() => this.$router.push({ name: "Key-List" }))
                })
                .finally(() => this.switchLoading())
        },
        filterDateTime(date) {
            return Helpers.filterDateTime(date)
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Key.Delete.Title")
        }
    }
}
</script>

<style scoped>
h6.title {
    margin-bottom: 0;
}
.message {
    box-shadow: none;
}
.message-body {
    border-radius: 0;
}
</style>
